import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";

const Politicas = () => {
  return (
    <div className="bg-white">
      {/* Navbar */}
      <header>
        <Navbar
          fluid
          rounded
          className="fixed inset-x-0 top-0 z-50 shadow-md bg-white"
        >
          <Navbar.Brand href="/">
            <img
              src="/logo512.png"
              className="mr-3 h-6 sm:h-9"
              alt="Beauty 2B"
            />
            <span className="self-center whitespace-nowrap text-xl text-fuchsia-950 font-semibold">
              Beauty 2B
            </span>
          </Navbar.Brand>
          <div className="lg:flex lg:justify-end">
            <Link to={"/entrar"} className="text-sm font-semibold text-gray-900">
              Entrar <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </Navbar>
      </header>

      {/* Hero Section */}
      <div className="relative isolate px-6 lg:px-8">
        {/* Decorative Gradient */}
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>

        {/* Content Section */}
        <div className="mx-auto max-w-4xl py-16 text-gray-900">
          <h1 className="text-4xl font-bold tracking-tight text-center mb-8">
            Política de Privacidade
          </h1>
          <div className="text-lg leading-7 space-y-6">
            <p>
              A <strong>Beauty 2B</strong> valoriza sua privacidade. Esta
              Política de Privacidade explica como coletamos, usamos e
              protegemos suas informações.
            </p>

            <h2 className="text-2xl font-semibold">1. Coleta de Dados</h2>
            <p>
              1.1. <strong>Informações Fornecidas pelo Usuário</strong>: Ao se
              registrar na plataforma, coletamos dados como:
            </p>
            <ul className="list-disc ml-6">
              <li>Nome;</li>
              <li>E-mail;</li>
              <li>Dados comerciais (quando aplicável).</li>
            </ul>
            <p>
              1.2. <strong>Cookies</strong>: Utilizamos cookies para:
            </p>
            <ul className="list-disc ml-6">
              <li>Autenticar usuários;</li>
              <li>Melhorar a experiência do usuário;</li>
              <li>
                Realizar campanhas de marketing (apenas com o consentimento do
                usuário).
              </li>
            </ul>

            <h2 className="text-2xl font-semibold">2. Uso dos Dados</h2>
            <p>
              2.1. <strong>Finalidade do Uso</strong>: Seus dados são utilizados
              para:
            </p>
            <ul className="list-disc ml-6">
              <li>Gerenciar sua conta;</li>
              <li>Facilitar conexões entre lojistas e fornecedores;</li>
              <li>Garantir a segurança da plataforma.</li>
            </ul>
            <p>
              2.2. <strong>Contato Entre Usuários</strong>: Lojistas podem ser
              encontrados por fornecedores para negociações comerciais, mas
              somente se consentirem em habilitar essa funcionalidade.
            </p>
            <p>
              2.3. <strong>Marketing</strong>: Os dados dos usuários podem ser
              utilizados para campanhas de marketing personalizadas, mediante
              consentimento.
            </p>

            <h2 className="text-2xl font-semibold">3. Compartilhamento de Dados</h2>
            <p>
              Seus dados não serão vendidos ou compartilhados com terceiros,
              exceto:
            </p>
            <ul className="list-disc ml-6">
              <li>
                Quando necessário para o funcionamento da plataforma (por
                exemplo, provedores de serviços);
              </li>
              <li>Quando exigido por lei.</li>
            </ul>

            <h2 className="text-2xl font-semibold">4. Segurança dos Dados</h2>
            <p>
              A <strong>Beauty 2B</strong> adota medidas técnicas e
              organizacionais para proteger suas informações contra acesso não
              autorizado, alteração ou destruição.
            </p>

            <h2 className="text-2xl font-semibold">5. Direitos do Usuário</h2>
            <p>
              Você tem o direito de:
            </p>
            <ul className="list-disc ml-6">
              <li>Acessar e corrigir suas informações pessoais;</li>
              <li>Revogar consentimentos de marketing a qualquer momento;</li>
              <li>
                Solicitar a exclusão de sua conta e dados associados.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold">6. Alterações na Política</h2>
            <p>
              A <strong>Beauty 2B</strong> pode atualizar esta Política de
              Privacidade periodicamente. Notificaremos os usuários sobre
              mudanças significativas.
            </p>

            <p>
              Em caso de dúvidas, entre em contato pelo e-mail:{" "}
              <a
                href="mailto:atendimento@beauty2b.com.br"
                className="text-fuchsia-700 underline"
              >
                atendimento@beauty2b.com.br
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Politicas;
