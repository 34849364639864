import { combineReducers } from "redux";
import authReducer from "./auth";
import userReducer from "./user";
import progressReducer from "./progress";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  progress: progressReducer,
});
