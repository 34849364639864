import React from "react";
import { Sidebar } from "flowbite-react";
import {
  HiX,
  HiHome,
  HiUserCircle,
  HiBriefcase,
  HiCreditCard,
} from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";

const SidebarComponent = ({ isSidebarVisible, toggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Obtém a role do usuário do estado
  const userRole = useSelector((state) => state.user?.profile?.role);

  const handleLogout = () => {
    dispatch(logout()); // Despacha a ação de logout
    navigate("/");
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-white shadow-md transition-transform duration-300 z-40 ${
        isSidebarVisible ? "translate-x-0" : "-translate-x-full"
      } lg:relative lg:translate-x-0 lg:fixed lg:w-64`}
    >
      <Sidebar
        aria-label="Main navigation sidebar"
        className="h-full lg:mt-3 mt-12 "
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {/* Renderiza Plano apenas para lojistas */}
            {userRole === "lojista" && (
            <Sidebar.Item as={Link} to="/app/home" icon={HiHome}>
              Home
            </Sidebar.Item>
            )}
            <Sidebar.Item as={Link} to="/app/user" icon={HiUserCircle}>
              Perfil
            </Sidebar.Item>

            {/* Renderiza Editar Fornecedores apenas para fornecedores */}
            {userRole === "fornecedor" && (
              <Sidebar.Item as={Link} to="/app/fornecedores" icon={HiBriefcase}>
                Editar Fornecedores
              </Sidebar.Item>
            )}

            {/* Renderiza Plano apenas para lojistas */}
            {userRole === "lojista" && (
              <Sidebar.Item as={Link} to="/app/planos" icon={HiCreditCard}>
                Plano
              </Sidebar.Item>
            )}

            <Sidebar.Item as={Link} onClick={handleLogout} icon={HiX}>
              Logout
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
};

export default SidebarComponent;
