// utils.js

/**
 * Cria um elemento <img> carregado a partir de uma URL ou Blob.
 * @param {string} url - A URL ou Blob da imagem.
 * @returns {Promise<HTMLImageElement>} - Promessa resolvida com o elemento <img>.
 */
export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.crossOrigin = "anonymous"; // Evita problemas de CORS com imagens externas
    image.src = url;
  });

/**
 * Converte um valor de graus para radianos.
 * @param {number} degreeValue - O valor em graus.
 * @returns {number} - O valor convertido em radianos.
 */
export const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;

export default async function getCroppedImg(imageSrc, pixelCrop, outputSize) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = outputSize;
  canvas.height = outputSize;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    outputSize,
    outputSize
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
}
