import React, { useState } from "react";
import { TextInput, Button, Badge, Label } from "flowbite-react";
import { FaTrash, FaPlus } from "react-icons/fa";

const ItemListManager = ({ items, setItems, label, placeholder }) => {
  const [inputValue, setInputValue] = useState("");
  const maxItems = 10;

  const handleAddItem = () => {
    if (!inputValue.trim()) return;
    if (items.length >= maxItems) {
      alert(`Você só pode adicionar até ${maxItems} ${label.toLowerCase()}.`);
      return;
    }
    if (items.includes(inputValue.trim())) {
      alert(`${label} já adicionada.`);
      return;
    }
    setItems([...items, inputValue.trim()]);
    setInputValue("");
  };

  const handleRemoveItem = (item) => {
    setItems(items.filter((i) => i !== item));
  };

  return (
    <div className="space-y-4">
      <Label htmlFor={label} className="block">
        {label}
      </Label>
      <div className="flex items-center gap-2">
        <TextInput
          id={label}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button
          gradientDuoTone="purpleToPink"
          onClick={handleAddItem}
          className="flex items-center justify-center"
        >
          <FaPlus />
        </Button>
      </div>
      {items.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {items.map((item, index) => (
            <Badge
              key={index}
              className="flex items-center space-x-2 bg-purple-500 text-white"
            >
              <span>{item}</span>
              <button
                type="button"
                onClick={() => handleRemoveItem(item)}
                className="ml-2"
              >
                <FaTrash className="w-4 h-4" />
              </button>
            </Badge>
          ))}
        </div>
      )}
      {items.length >= maxItems && (
        <p className="text-sm text-red-500">
          Limite de {maxItems} {label.toLowerCase()}s atingido.
        </p>
      )}
    </div>
  );
};

export default ItemListManager;
