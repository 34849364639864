import React, { useState } from "react";
import { TextInput, Button, Label, Checkbox, Avatar } from "flowbite-react";
import { HiOutlineZoomIn, HiOutlineZoomOut } from "react-icons/hi";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils";

const LojaForm = ({
  lojaForm,
  handleLojaChange,
  handleSubmitLoja,
  cpfCnpjError,
  profilePicture,
  onProfilePictureUpdate,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [error, setError] = useState("");
  const [isEditingPhoto, setIsEditingPhoto] = useState(false);

  const validateCpfCnpj = (cpfCnpj) => {
    const regex = /^(?:\d{11}|\d{14})$/; // Valida CPF ou CNPJ
    return regex.test(cpfCnpj);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      if (file.size > 5 * 1024 * 1024) {
        setError("Por favor, selecione uma imagem menor que 5MB.");
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width === img.height) {
          onProfilePictureUpdate(file);
          setIsEditingPhoto(false);
        } else {
          setSelectedFile(img.src);
          setIsEditingPhoto(true);
        }
      };
      setError("");
    } else {
      setError("Por favor, selecione um arquivo de imagem válido.");
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    try {
      const blob = await getCroppedImg(selectedFile, croppedAreaPixels, 300);
      onProfilePictureUpdate(blob);
      setIsEditingPhoto(false);
    } catch (err) {
      console.error("Erro ao cortar a imagem:", err);
    }
  };

  return (
    <form className="mt-10 space-y-8" onSubmit={handleSubmitLoja}>
      <h2 className="text-2xl font-semibold text-gray-700">
        Informações da Loja
      </h2>

      {/* Avatar */}
      <div className="flex flex-col items-center space-y-4">
        <Avatar
          img={
            profilePicture instanceof Blob
              ? URL.createObjectURL(profilePicture) // Mostra o Blob localmente
              : profilePicture
              ? `${process.env.REACT_APP_API_URL}${profilePicture}` // Mostra a foto persistida
              : "/store.png" // Imagem padrão
          }
          alt="Foto de perfil da loja"
          rounded
          size="xl"
        />
        <label htmlFor="foto" className="cursor-pointer">
          <Button
            gradientDuoTone="purpleToPink"
            type="button"
            onClick={() => document.getElementById("file-input").click()}
          >
            Editar Foto de Perfil da Loja
          </Button>
        </label>
        <input
          id="file-input"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>

      {/* Cropper */}
      {isEditingPhoto && selectedFile && (
        <div className="relative w-full h-72 mt-6">
          <Cropper
            image={selectedFile}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <div className="flex justify-between items-center mt-4">
            <Button
              gradientDuoTone="purpleToPink"
              size="sm"
              onClick={() => setZoom((prev) => Math.max(prev - 0.1, 1))}
              className="flex items-center space-x-2"
            >
              <HiOutlineZoomOut className="mr-2" />
              Zoom Out
            </Button>
            <Button
              gradientDuoTone="purpleToPink"
              size="sm"
              onClick={() => setZoom((prev) => Math.min(prev + 0.1, 3))}
              className="flex items-center space-x-2"
            >
              <HiOutlineZoomIn className="mr-2" />
              Zoom In
            </Button>
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            <Button
              gradientDuoTone="purpleToPink"
              type="button"
              size="sm"
              onClick={handleCropSave}
            >
              Salvar Corte
            </Button>
            <Button
              gradientDuoTone="purpleToPink"
              type="button"
              size="sm"
              onClick={() => setIsEditingPhoto(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}

      {/* Campos da loja */}
      <div>
        <Label htmlFor="nome_fantasia" className="mb-2 block">
          Nome Fantasia
        </Label>
        <TextInput
          id="nome_fantasia"
          name="nome_fantasia"
          type="text"
          placeholder="Nome da loja"
          value={lojaForm.nome_fantasia}
          onChange={handleLojaChange}
        />
      </div>
      <div>
        <Label htmlFor="cpf_cnpj" className="mb-2 block">
          CPF/CNPJ
        </Label>
        <TextInput
          id="cpf_cnpj"
          name="cpf_cnpj"
          type="text"
          placeholder="CPF ou CNPJ da loja"
          value={lojaForm.cpf_cnpj}
          onChange={handleLojaChange}
          required
        />
        {cpfCnpjError && <p className="text-red-500 text-sm">{cpfCnpjError}</p>}
      </div>

      {/* Outros campos */}
      <div>
        <Label htmlFor="telefone" className="mb-2 block">
          Telefone
        </Label>
        <TextInput
          id="telefone"
          name="telefone"
          type="text"
          placeholder="Número de telefone"
          value={lojaForm.telefone}
          onChange={handleLojaChange}
        />
      </div>
      <div>
        <Label htmlFor="whatsapp" className="mb-2 block">
          WhatsApp
        </Label>
        <TextInput
          id="whatsapp"
          name="whatsapp"
          type="text"
          placeholder="Número do WhatsApp da loja"
          value={lojaForm.whatsapp}
          onChange={handleLojaChange}
        />
      </div>

      {/* Opt-in */}
      <div className="flex items-center">
        <Checkbox
          id="opt_in"
          name="opt_in"
          checked={lojaForm.opt_in}
          onChange={handleLojaChange}
        />
        <Label htmlFor="opt_in" className="ml-2">
          Aceito receber mensagens promocionais de fornecedores.
        </Label>
      </div>

      {/* Botão de Salvar */}
      <Button
        type="submit"
        gradientDuoTone="purpleToPink"
        size="lg"
        className="w-full"
      >
        Salvar Informações da Loja
      </Button>
    </form>
  );
};

export default LojaForm;
