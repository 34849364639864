// reducers/progress.js
import { START_PROGRESS, STOP_PROGRESS } from "../actions/progress";

const initialState = {
  loading: false,
};

const progressReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_PROGRESS:
      return { loading: true };
    case STOP_PROGRESS:
      return { loading: false };
    default:
      return state;
  }
};

export default progressReducer;
