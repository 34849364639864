import React from "react";
import { TextInput, Button, Label } from "flowbite-react";

const PasswordForm = ({
  passwordForm,
  handlePasswordChange,
  handleSubmitPassword,
  passwordError,
}) => {
  return (
    <form className="mt-10 space-y-8" onSubmit={handleSubmitPassword}>
      <h2 className="text-2xl font-semibold text-gray-700">Alterar Senha</h2>
      <div>
        <Label htmlFor="senhaAtual" className="mb-2 block">
          Senha Atual
        </Label>
        <TextInput
          id="senhaAtual"
          name="senhaAtual"
          type="password"
          placeholder="Digite sua senha atual"
          value={passwordForm.senhaAtual}
          onChange={handlePasswordChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="novaSenha" className="mb-2 block">
          Nova Senha
        </Label>
        <TextInput
          id="novaSenha"
          name="novaSenha"
          type="password"
          placeholder="Digite sua nova senha"
          value={passwordForm.novaSenha}
          onChange={handlePasswordChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="confirmarNovaSenha" className="mb-2 block">
          Confirmar Nova Senha
        </Label>
        <TextInput
          id="confirmarNovaSenha"
          name="confirmarNovaSenha"
          type="password"
          placeholder="Confirme sua nova senha"
          value={passwordForm.confirmarNovaSenha || ""}
          onChange={(e) =>
            handlePasswordChange({
              target: { name: "confirmarNovaSenha", value: e.target.value },
            })
          }
          required
        />
      </div>
      {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
      <div>
        <Button
          type="submit"
          gradientDuoTone="purpleToPink"
          size="lg"
          className="w-full"
        >
          Alterar Senha
        </Button>
      </div>
    </form>
  );
};

export default PasswordForm;
