import { Navbar, Button } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate(); // Hook do React Router para redirecionar

  const handleRedirect = (role) => {
    navigate(`/cadastrar?role=${role}`);
  };

  return (
    <div className="bg-white">
      {/* Navbar */}
      <header>
        <Navbar
          fluid
          rounded
          className="fixed inset-x-0 top-0 z-50 shadow-md bg-white"
        >
          <Navbar.Brand href="/">
            <img
              src="/logo512.png"
              className="mr-3 h-6 sm:h-9"
              alt="Beauty 2B"
            />
            <span className="self-center whitespace-nowrap text-xl text-fuchsia-950 font-semibold">
              Beauty 2B
            </span>
          </Navbar.Brand>
          {/* <Navbar.Toggle /> */}
          {/* <Navbar.Collapse>
              <Navbar.Link href="#" active>
                Product
              </Navbar.Link>
              <Navbar.Link href="#">Features</Navbar.Link>
              <Navbar.Link href="#">Marketplace</Navbar.Link>
              <Navbar.Link href="#">Company</Navbar.Link>
            </Navbar.Collapse> */}
          <div className=" lg:flex lg:justify-end">
            <Link to={"/entrar"} className="text-sm font-semibold text-gray-900">
              Entrar <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </Navbar>
      </header>

      {/* Hero Section */}
      <div className="relative isolate px-6 lg:px-8">
        {/* Decorative Gradient */}
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>

        {/* Hero Content */}
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Announcing our next round of funding.{" "}
                <a href="#" className="font-semibold text-indigo-600">
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div> */}
          <div className="text-center">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-7xl">
              Conectamos você ao futuro do mercado de beleza
            </h1>
            <p className="mt-8 text-lg leading-8 text-gray-600">
              Simplificamos as negociações entre lojistas e fornecedores do
              setor de beleza, permitindo acesso direto a produtos de alta
              qualidade, reduzindo custos e ampliando oportunidades de negócios
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Button
                size="lg"
                gradientDuoTone="purpleToPink"
                onClick={() => handleRedirect("lojista")}
              >
                Sou Lojista
              </Button>

              {/* Link Sou Fornecedor */}
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleRedirect("fornecedor");
                }}
                className="text-sm font-semibold text-gray-900 hover:underline hover:text-indigo-600"
              >
                Sou Fornecedor <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>

        {/* Bottom Decorative Gradient */}
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            ></div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
