import axios from "axios";
axios.defaults.withCredentials = true;

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL)

export function login(email, password) {
  return axios.post(`${API_URL}/auth/login`, { email, password });
}

export function logout() {
  return axios.post(`${API_URL}/auth/logout`);
}

export function checkAuthStatus() {
  return axios.get(`${API_URL}/auth/status`);
}
