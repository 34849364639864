import React from "react";
import { Card, Button } from "flowbite-react";
import { HiOutlineClock } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const Planos = () => {
  const navigate = useNavigate();

  const handleClique = () => {
    navigate("/app/user/");
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Card className="text-center max-w-md">
        <div className="flex flex-col items-center gap-4">
          <HiOutlineClock className="text-purple-600 text-6xl" />
          <h1 className="text-2xl font-semibold text-gray-800">Em breve!</h1>
          <p className="text-gray-600">
            Estamos trabalhando para oferecer planos incríveis que vão ajudar
            você a explorar os melhores fornecedores da sua região. Enquanto
            isso, aproveite para editar e personalizar seu perfil!
          </p>
          <Button
            gradientDuoTone="purpleToPink"
            className="mt-4"
            onClick={handleClique}
          >
            Editar Meu Perfil
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default Planos;
