import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatus } from "./actions/auth";
import { loadUser } from "./actions/user";
import { Routes, Route } from "react-router-dom";

import LandingPage from "./componentes/LandingPage";
import CadastraUsuario from "./componentes/CadastraUsuario";
import LoginPage from "./componentes/LoginPage";
import Dashboard from "./componentes/Dashboard";
import ProtectedRoute from "./componentes/ProtectedRoute";
import Spa from "./componentes/Spa";
import EditUser from "./componentes/EditUser.js";
import Fornecedores from "./componentes/Fornecedores/index.js";
import EditFornecedor from "./componentes/Fornecedores/EditFornecedor/index.js";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import ListaFornecedores from "./componentes/ListaFornecedores/index.js";
import CreateFornecedor from "./componentes/Fornecedores/CreateFornecedor/index.js";
import Planos from "./componentes/Planos/index.js";
import Termos from "./componentes/Termos/index.js";
import Politicas from "./componentes/Politicas/index.js";

NProgress.configure({ showSpinner: false, trickle: false });

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, loading: authLoading } = useSelector(
    (state) => state.auth
  );
  const { loading: userLoading } = useSelector((state) => state.user);

  const isAppLoading = authLoading || userLoading;

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadUser());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAppLoading) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [isAppLoading]);

  if (isAppLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <h1 className="text-lg font-bold text-gray-800">Carregando...</h1>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/cadastrar" element={<CadastraUsuario />} />
      <Route path="/entrar" element={<LoginPage />} />
      <Route path="/termos" element={<Termos />} />
      <Route path="/politicas" element={<Politicas />} />
      <Route
        path="/app/*"
        element={
          <ProtectedRoute>
            <Spa />
          </ProtectedRoute>
        }
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="planos" element={<Planos />} />
        <Route path="user" element={<EditUser />} />
        <Route path="fornecedores" element={<Fornecedores />} />
        <Route path="fornecedores/adicionar" element={<CreateFornecedor />} />
        <Route path="fornecedores/edit/:id" element={<EditFornecedor />} />
        <Route path="home" element={<ListaFornecedores />} />
      </Route>
    </Routes>
  );
}

export default App;
