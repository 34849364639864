import React, { useState } from "react";
import { Card, Avatar, Button, Select, TextInput } from "flowbite-react";
import {
  HiOutlineMail,
  HiOutlineTag,
  HiOutlineClipboardList,
  HiOutlineSearch,
  HiOutlineFilter,
} from "react-icons/hi";
import { buscarFornecedoresPorEstado } from "../../api/buscarFornecedores";

const normalizeText = (text) => {
  return (text || "") // Garante que text é uma string
    .toString() // Converte o valor para string, caso necessário
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, ""); // Remove acentos e espaços extras
};

const ListaFornecedores = () => {
  const [fornecedores, setFornecedores] = useState([]);
  const [erro, setErro] = useState(null);
  const [estado, setEstado] = useState("");
  const [marcaFiltro, setMarcaFiltro] = useState("");
  const [categoriaFiltro, setCategoriaFiltro] = useState("");
  const [mostrarFiltros, setMostrarFiltros] = useState(false); // Controla a exibição dos filtros

  const handleBuscar = () => {
    if (!estado) {
      setErro("Por favor, selecione um estado para realizar a busca.");
      return;
    }

    buscarFornecedoresPorEstado(estado)
      .then((response) => {
        setFornecedores(response.data);
        setErro(null);
      })
      .catch((error) => {
        setFornecedores([]);
        const errorMessage =
          error.response?.data?.error ||
          "Erro ao buscar fornecedores. Tente novamente mais tarde.";
        setErro(errorMessage);
        console.error(error);
      });
  };

  const filteredFornecedores = fornecedores.filter((fornecedor) => {
    const normalizedCategorias = normalizeText(
      Array.isArray(fornecedor.categorias)
        ? fornecedor.categorias.join(", ")
        : ""
    );
    const normalizedMarcas = normalizeText(
      Array.isArray(fornecedor.marcas) ? fornecedor.marcas.join(", ") : ""
    );
    const normalizedMarcaFiltro = normalizeText(marcaFiltro);
    const normalizedCategoriaFiltro = normalizeText(categoriaFiltro);

    return (
      (!marcaFiltro || normalizedMarcas.includes(normalizedMarcaFiltro)) &&
      (!categoriaFiltro ||
        normalizedCategorias.includes(normalizedCategoriaFiltro))
    );
  });

  return (
    <div className="flex flex-col items-center w-full max-w-6xl mx-auto mt-10 px-4">
      <div className="flex flex-wrap md:flex-nowrap gap-4 mb-6 w-full max-w-md">
        {/* Select do Estado */}
        <Select
          id="estado"
          required
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          className="w-full md:w-3/5"
        >
          <option value="">Selecione um estado</option>
          <option value="SP">São Paulo</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="MG">Minas Gerais</option>
          <option value="DF">Distrito Federal</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="PR">Paraná</option>
          <option value="BA">Bahia</option>
          <option value="SC">Santa Catarina</option>
          <option value="GO">Goiás</option>
          <option value="PE">Pernambuco</option>
          <option value="CE">Ceará</option>
          <option value="PA">Pará</option>
          <option value="MT">Mato Grosso</option>
          <option value="ES">Espírito Santo</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="AM">Amazonas</option>
          <option value="MA">Maranhão</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="PB">Paraíba</option>
          <option value="AL">Alagoas</option>
          <option value="PI">Piauí</option>
          <option value="SE">Sergipe</option>
          <option value="RO">Rondônia</option>
          <option value="TO">Tocantins</option>
          <option value="AC">Acre</option>
          <option value="AP">Amapá</option>
          <option value="RR">Roraima</option>
        </Select>

        {/* Button Group */}
        <div className="flex gap-2 w-full md:w-2/5">
          <Button
            gradientDuoTone="purpleToPink"
            size="sm"
            onClick={handleBuscar}
            className="flex items-center gap-2 w-full"
          >
            <HiOutlineSearch className="h-5 w-5 mr-3" />
            Buscar
          </Button>

          <Button
            gradientDuoTone="purpleToPink"
            size="sm"
            onClick={() => setMostrarFiltros(!mostrarFiltros)}
            className="flex items-center gap-2 w-full"
          >
            <HiOutlineFilter className="h-5 w-5 mr-3" />
            {mostrarFiltros ? "Esconder" : "Filtrar"}
          </Button>
        </div>
      </div>

      {/* Filtros Adicionais */}
      {mostrarFiltros && (
        <div className="w-full flex flex-col md:flex-row gap-4 mb-6 max-w-md">
          <TextInput
            placeholder="Filtrar por marca"
            value={marcaFiltro}
            onChange={(e) => setMarcaFiltro(e.target.value)}
            className="w-full"
          />
          <TextInput
            placeholder="Filtrar por categorias"
            value={categoriaFiltro}
            onChange={(e) => setCategoriaFiltro(e.target.value)}
            className="w-full"
          />
        </div>
      )}

      {erro && <p className="text-red-500 text-center mb-4">{erro}</p>}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full">
        {filteredFornecedores.length > 0
          ? filteredFornecedores.map((fornecedor) => (
              <Card
                key={fornecedor.id}
                className="flex flex-col items-center text-center p-4"
              >
                <Avatar
                  img={
                    fornecedor.profile_picture
                      ? `${process.env.REACT_APP_API_URL}${fornecedor.profile_picture}`
                      : "/supplier.png"
                  }
                  alt="Foto de perfil do fornecedor"
                  rounded
                  size="lg"
                  className="mb-4"
                />
                <h5 className="text-lg font-semibold text-gray-900 mb-2">
                  {fornecedor.nome_fantasia}
                </h5>

                <div className="text-gray-700 space-y-2">
                  {fornecedor.email_contato && (
                    <p className="flex items-center justify-center gap-2">
                      <HiOutlineMail className="text-gray-500" />
                      {fornecedor.email_contato}
                    </p>
                  )}

                  {fornecedor.categorias && (
                    <p className="flex items-center justify-center gap-2">
                      <HiOutlineClipboardList className="text-gray-500" />
                      {fornecedor.categorias.join(", ")}
                    </p>
                  )}

                  {fornecedor.marcas && (
                    <p className="flex items-center justify-center gap-2">
                      <HiOutlineTag className="text-gray-500" />
                      {fornecedor.marcas.join(", ")}
                    </p>
                  )}
                </div>
              </Card>
            ))
          : null}
      </div>
    </div>
  );
};

export default ListaFornecedores;
