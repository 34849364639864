import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CHECK_AUTH_STATUS } from "../actions/auth";

const initialState = {
  isAuthenticated: false,
  // token: null,
  error: null,
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        error: null,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        error: action.payload,
      };
    case LOGOUT:
      return initialState;
    case CHECK_AUTH_STATUS:
      return {
        ...state,
        isAuthenticated: action.payload.authenticated,
      };
    default:
      return state;
  }
};

export default authReducer;
