import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT_USER,
  LOAD_USER_SUCCESS,
  START_LOADING,
  STOP_LOADING,
  UPDATE_LOJA_SUCCESS,
  UPDATE_LOJA_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_FORNECEDOR_SUCCESS,
  UPDATE_FORNECEDOR_FAIL,
  CRIAR_FORNECEDOR_SUCCESS,
  CRIAR_FORNECEDOR_FAIL,
} from "../actions/user";

const initialState = {
  profile: null,
  lojas: [],
  fornecedores: [],
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        error: null,
        loading: false, // Garante que o loading seja desativado
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        error: null,
        loading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        profile: null,
        error: action.payload,
        loading: false,
      };
    case UPDATE_LOJA_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          loja: action.payload, // Atualiza somente a loja
        },
        error: null,
        loading: false,
      };
    case UPDATE_LOJA_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload, // Atualiza o nome e email
        },
        error: null,
        loading: false,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_FORNECEDOR_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          fornecedores: state.profile.fornecedores.map((fornecedor) =>
            fornecedor.id === action.payload.id ? action.payload : fornecedor
          ),
        },
        error: null,
        loading: false,
      };
    case UPDATE_FORNECEDOR_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CRIAR_FORNECEDOR_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          fornecedores: [...state.profile.fornecedores, action.payload],
        },
        error: null,
        loading: false,
      };
    case CRIAR_FORNECEDOR_FAIL:
      return { ...state, error: action.payload, loading: false };
    case LOGOUT_USER:
      return initialState; // Limpa o estado ao fazer logout
    default:
      return state;
  }
};

export default userReducer;
