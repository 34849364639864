import React, { useState, useEffect } from "react";
import { TextInput, Button, Label } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error: loginError, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (!formData.email || !formData.password) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    dispatch(login(formData.email, formData.password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/app/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="relative isolate bg-white px-6 lg:px-8">
      {/* Background decorativo */}
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>

      {/* Formulário de Login */}
      <div className="mx-auto max-w-lg py-16 sm:py-20 lg:py-24">
        <h1 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
          Bem-vindo de volta!
        </h1>
        <p className="mt-4 text-center text-gray-600">
          Que bom te ver por aqui! Entre com suas credenciais e continue sua
          jornada
        </p>

        <form className="mt-10 space-y-6" onSubmit={handleSubmit}>
          {/* Email */}
          <div>
            <Label htmlFor="email" className="mb-2 block">
              Email
            </Label>
            <TextInput
              id="email"
              name="email"
              type="email"
              placeholder="seu@email.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Senha */}
          <div>
            <Label htmlFor="password" className="mb-2 block">
              Senha
            </Label>
            <TextInput
              id="password"
              name="password"
              type="password"
              placeholder="Digite sua senha"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          {/* Exibir Erros */}
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {loginError && (
            <p className="text-red-500 text-sm">
              Erro ao fazer login: {loginError}
            </p>
          )}

          {/* Botão de Login */}
          <div>
            <Button
              type="submit"
              gradientDuoTone="purpleToPink"
              size="lg"
              className="w-full"
              disabled={loading}
            >
              {loading ? "Entrando..." : "Entrar"}
            </Button>
          </div>
        </form>

        {/* Termos e Cadastro */}
        <div className="mt-6 space-y-4 text-center text-sm text-gray-600">
          {/* Termos de Uso */}
          <p>
            Ao fazer login, você concorda com nossos{" "}
            <Link
              to="/termos"
              className="font-medium text-indigo-600 hover:text-indigo-800"
            >
              Termos de Uso
            </Link>{" "}
            e{" "}
            <Link
              to="/politicas"
              className="font-medium text-indigo-600 hover:text-indigo-800"
            >
              Políticas de Privacidade
            </Link>
            .
          </p>

          {/* Cadastro */}
          <p>
            Não tem uma conta? Cadastre-se como{" "}
            <Link
              to="/cadastrar?role=fornecedor"
              className="font-medium text-indigo-600 hover:text-indigo-800"
            >
              Fornecedor
            </Link>{" "}
            ou{" "}
            <Link
              to="/cadastrar?role=lojista"
              className="font-medium text-indigo-600 hover:text-indigo-800"
            >
              Lojista
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
