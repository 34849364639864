import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Button } from "flowbite-react";
import getCroppedImg from "../../../utils";

const CropImage = ({
  selectedFile,
  formData,
  setFormData,
  setIsEditingPhoto,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleCropSave = async () => {
    try {
      const blob = await getCroppedImg(selectedFile, croppedAreaPixels, 300);
      setFormData({ ...formData, profile_picture: blob });
      setIsEditingPhoto(false);
    } catch (err) {
      console.error("Erro ao cortar a imagem:", err);
    }
  };

  return (
    <div className="relative w-full h-72 mt-6">
      <Cropper
        image={selectedFile}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={(_, croppedAreaPixels) =>
          setCroppedAreaPixels(croppedAreaPixels)
        }
      />
      <div className="flex justify-end space-x-2 mt-6">
        <Button gradientDuoTone="purpleToPink" onClick={handleCropSave}>
          Salvar Corte
        </Button>
        <Button
          gradientDuoTone="purpleToPink"
          onClick={() => setIsEditingPhoto(false)}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default CropImage;
