const Dashboard = () => {
  return (
      <div className="p-8">
        <h1 className="text-3xl font-bold">Bem-vindo ao Dashboard</h1>
        <p className="mt-4">
          Apenas usuários autenticados podem acessar esta página.
        </p>
      </div>
  );
};

export default Dashboard;
  