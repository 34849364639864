import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Busca todos os fornecedores (rota: fornecedores/todos)
export function buscarTodosFornecedores() {
  return axios.get(`${API_URL}/fornecedores/todos`);
}

export const buscarFornecedoresPorEstado = async (estado) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/fornecedores/estado`,
    {
      params: { estado }, // Adiciona o estado nos parâmetros
    }
  );
  return response;
};
