import { Outlet } from "react-router-dom";
import { HiMenu, HiX } from "react-icons/hi";
import SidebarComponent from "../SidebarComponent";
import ProtectedLayout from "../ProtectedLayout";
import { useState } from "react";

const Spa = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div className="flex h-screen">
      {/* Botão de alternar sempre fixo */}
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-50 p-2 text-gray-500 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200 lg:hidden"
      >
        {isSidebarVisible ? (
          <HiX className="w-6 h-6" />
        ) : (
          <HiMenu className="w-6 h-6" />
        )}
      </button>

      {/* Sidebar */}
      <SidebarComponent
        isSidebarVisible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
      />

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto transition-all duration-300">
        <ProtectedLayout>
          <Outlet />
        </ProtectedLayout>
      </div>
    </div>
  );
};

export default Spa;
