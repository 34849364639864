import React, { useState } from "react";
import { TextInput, Label, Checkbox, Button, Badge } from "flowbite-react";

const estadosBrasileiros = [
  "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
  "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
  "RS", "RO", "RR", "SC", "SP", "SE", "TO",
];

const FormFields = ({ formData, setFormData }) => {
  const [estadoSelecionado, setEstadoSelecionado] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleAddEstado = () => {
    if (!estadoSelecionado) return;

    if (!(formData.estados || []).includes(estadoSelecionado)) {
      setFormData({
        ...formData,
        estados: [...(formData.estados || []), estadoSelecionado],
      });
      setEstadoSelecionado("");
    }
  };

  const handleRemoveEstado = (estado) => {
    setFormData({
      ...formData,
      estados: (formData.estados || []).filter((e) => e !== estado),
    });
  };

  return (
    <>
      <div>
        <Label htmlFor="nome_fantasia" className="mb-2 block">
          Nome Fantasia
        </Label>
        <TextInput
          id="nome_fantasia"
          name="nome_fantasia"
          type="text"
          placeholder="Nome do fornecedor"
          value={formData.nome_fantasia}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="cpf_cnpj" className="mb-2 block">
          CPF/CNPJ
        </Label>
        <TextInput
          id="cpf_cnpj"
          name="cpf_cnpj"
          type="text"
          placeholder="CPF ou CNPJ"
          value={formData.cpf_cnpj}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="email_contato" className="mb-2 block">
          Email de Contato
        </Label>
        <TextInput
          id="email_contato"
          name="email_contato"
          type="email"
          placeholder="Email do fornecedor"
          value={formData.email_contato}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="telefone" className="mb-2 block">
          Telefone
        </Label>
        <TextInput
          id="telefone"
          name="telefone"
          type="text"
          placeholder="Telefone do fornecedor"
          value={formData.telefone}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label htmlFor="whatsapp" className="mb-2 block">
          WhatsApp
        </Label>
        <TextInput
          id="whatsapp"
          name="whatsapp"
          type="text"
          placeholder="Número do WhatsApp"
          value={formData.whatsapp}
          onChange={handleChange}
        />
      </div>

      {/* Configuração da Cobertura Regional */}
      <div className="border rounded-lg p-4 mt-6">
        <h3 className="text-lg font-semibold mb-4">Qual a região é atendida por este fornecedor?</h3>

        <div className="flex items-center mb-4">
          <Checkbox
            id="nacional"
            name="nacional"
            checked={formData.nacional}
            onChange={handleChange}
          />
          <Label htmlFor="nacional" className="ml-2">
            Todos os estados do Brasil
          </Label>
        </div>

        {/* Estados Atendidos */}
        {!formData.nacional && (
          <>
            <div>
              <div className="flex items-center gap-2">
                <select
                  id="estados"
                  className="border border-gray-300 rounded-lg px-3 py-2"
                  value={estadoSelecionado}
                  onChange={(e) => setEstadoSelecionado(e.target.value)}
                >
                  <option value="">Selecione um estado</option>
                  {estadosBrasileiros.map((estado) => (
                    <option key={estado} value={estado}>
                      {estado}
                    </option>
                  ))}
                </select>
                <Button
                  gradientDuoTone="purpleToPink"
                  type="button"
                  onClick={handleAddEstado}
                >
                  Adicionar
                </Button>
              </div>
            </div>

            {formData.estados?.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-4">
                {formData.estados.map((estado) => (
                  <Badge
                    key={estado}
                    className="flex items-center bg-purple-500 text-white"
                  >
                    {estado}
                    <button
                      type="button"
                      onClick={() => handleRemoveEstado(estado)}
                      className="ml-2"
                    >
                      ✕
                    </button>
                  </Badge>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FormFields;
