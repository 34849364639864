import { useState } from "react";
import { useDispatch } from "react-redux";
import { TextInput, Button, Label } from "flowbite-react";
import { atualizarUsuarioAction } from "../../actions/user";

const UserForm = ({ profile }) => {
  const dispatch = useDispatch();
  // Estado para o formulário do usuário
  const [userForm, setUserForm] = useState({
    nome: profile?.nome || "",
    email: profile?.email || "",
  });

  // Atualiza os campos do formulário do usuário
  const handleUserChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const handleSubmitUser = (e) => {
    e.preventDefault();
    dispatch(atualizarUsuarioAction(userForm));
  };

  return (
    <form className="mt-10 space-y-8" onSubmit={handleSubmitUser}>
      <h2 className="text-2xl font-semibold text-gray-700">
        Informações Pessoais
      </h2>
      <div>
        <Label htmlFor="nome" className="mb-2 block">
          Nome
        </Label>
        <TextInput
          id="nome"
          name="nome"
          type="text"
          placeholder="Seu nome completo"
          value={userForm.nome}
          onChange={handleUserChange}
          required
        />
      </div>
      <div>
        <Label htmlFor="email" className="mb-2 block">
          Email
        </Label>
        <TextInput
          id="email"
          name="email"
          type="email"
          placeholder="seu@email.com"
          value={userForm.email}
          onChange={handleUserChange}
          required
        />
      </div>
      <div>
        <Button
          type="submit"
          gradientDuoTone="purpleToPink"
          size="lg"
          className="w-full"
        >
          Salvar Informações Pessoais
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
