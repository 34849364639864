import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";

const Termos = () => {
  return (
    <div className="bg-white">
      {/* Navbar */}
      <header>
        <Navbar
          fluid
          rounded
          className="fixed inset-x-0 top-0 z-50 shadow-md bg-white"
        >
          <Navbar.Brand href="/">
            <img
              src="/logo512.png"
              className="mr-3 h-6 sm:h-9"
              alt="Beauty 2B"
            />
            <span className="self-center whitespace-nowrap text-xl text-fuchsia-950 font-semibold">
              Beauty 2B
            </span>
          </Navbar.Brand>
          <div className="lg:flex lg:justify-end">
            <Link to={"/entrar"} className="text-sm font-semibold text-gray-900">
              Entrar <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </Navbar>
      </header>

      {/* Hero Section */}
      <div className="relative isolate px-6 lg:px-8">
        {/* Decorative Gradient */}
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>

        {/* Content Section */}
        <div className="mx-auto max-w-4xl py-16 text-gray-900">
          <h1 className="text-4xl font-bold tracking-tight text-center mb-8">
            Termos de Uso
          </h1>
          <div className="text-lg leading-7 space-y-6">
            <p>
              Bem-vindo à <strong>Beauty 2B</strong>! Estes Termos de Uso
              regem o uso de nossa plataforma. Ao criar uma conta e utilizar
              nossos serviços, você concorda com estes termos. Caso não
              concorde, pedimos que não utilize nossos serviços.
            </p>

            <h2 className="text-2xl font-semibold">1. Aceitação dos Termos</h2>
            <p>
              Ao criar uma conta, o usuário declara ter lido, entendido e
              concordado com os Termos de Uso e com a Política de Privacidade da{" "}
              <strong>Beauty 2B</strong>.
            </p>

            <h2 className="text-2xl font-semibold">
              2. Cadastro e Responsabilidades do Usuário
            </h2>
            <p>
              2.1. <strong>Veracidade das Informações</strong>: O usuário é
              responsável por fornecer informações verdadeiras, completas e
              atualizadas durante o cadastro. Dados falsos ou enganosos poderão
              resultar na suspensão ou exclusão da conta.
            </p>
            <p>
              2.2. <strong>Cadastro de Empresas</strong>: Ao cadastrar uma
              empresa na plataforma, o usuário declara que:
            </p>
            <ul className="list-disc ml-6">
              <li>
                É o representante legal ou possui autorização para representar
                comercialmente a empresa cadastrada.
              </li>
              <li>
                Está ciente de que a empresa será visível para lojistas na
                plataforma, conforme o objetivo do sistema.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold">3. Uso da Plataforma</h2>
            <p>
              3.1. <strong>Proibição de Conteúdo Ofensivo ou Inadequado</strong>:
              É estritamente proibido publicar mensagens ofensivas,
              preconceituosas, material NSFW (Not Safe for Work) ou qualquer
              outro conteúdo inadequado nos campos destinados a informações
              comerciais, incluindo:
            </p>
            <ul className="list-disc ml-6">
              <li>Imagens de produtos;</li>
              <li>Fotos de perfil;</li>
              <li>Textos de descrição.</li>
            </ul>
            <p>
              A <strong>Beauty 2B</strong> se reserva o direito de moderar,
              suspender ou excluir contas que violem esta regra.
            </p>

            <p>
              3.2. <strong>Interação Entre Usuários</strong>: Lojistas podem
              optar por permitir que fornecedores entrem em contato diretamente
              com eles (opt-in). Essa funcionalidade é ativada exclusivamente
              mediante consentimento explícito do lojista.
            </p>

            <h2 className="text-2xl font-semibold">4. Cookies e Dados Pessoais</h2>
            <p>
              4.1. Utilizamos cookies para:
            </p>
            <ul className="list-disc ml-6">
              <li>Gerenciar logins e autenticações;</li>
              <li>
                Coletar dados para marketing, desde que o usuário permita.
              </li>
            </ul>
            <p>
              4.2. Para mais detalhes sobre o uso de cookies, consulte nossa{" "}
              <Link
                to="/politicas"
                className="text-fuchsia-700 underline hover:text-fuchsia-900"
              >
                Política de Privacidade
              </Link>
              .
            </p>

            <h2 className="text-2xl font-semibold">
              5. Direitos e Responsabilidades da Beauty 2B
            </h2>
            <p>
              5.1. <strong>Moderação e Monitoramento</strong>: A{" "}
              <strong>Beauty 2B</strong> se reserva o direito de monitorar as
              atividades na plataforma e de moderar ou remover qualquer conteúdo
              que viole os Termos de Uso ou leis aplicáveis.
            </p>
            <p>
              5.2. <strong>Indisponibilidade do Serviço</strong>: A plataforma
              pode ficar indisponível temporariamente para manutenção ou por
              motivos técnicos. A <strong>Beauty 2B</strong> não se
              responsabiliza por perdas decorrentes dessa indisponibilidade.
            </p>

            <h2 className="text-2xl font-semibold">
              6. Propriedade Intelectual
            </h2>
            <p>
              Todo o conteúdo da plataforma, incluindo logos, textos, imagens e
              design, é de propriedade exclusiva da <strong>Beauty 2B</strong>{" "}
              e não pode ser copiado, distribuído ou reproduzido sem autorização
              prévia.
            </p>

            <h2 className="text-2xl font-semibold">
              7. Legislação Aplicável
            </h2>
            <p>
              Estes Termos de Uso são regidos pelas leis brasileiras. Em caso de
              disputa, fica eleito o foro da cidade de <strong>Catalão</strong>{" "}
              para resolução.
            </p>

            <p>
              Em caso de dúvidas, entre em contato pelo e-mail:{" "}
              <a
                href="mailto:atendimento@beauty2b.com.br"
                className="text-fuchsia-700 underline"
              >
                atendimento@beauty2b.com.br
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Termos;
