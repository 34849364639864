import * as api from "../api/auth";
import { LOAD_USER_SUCCESS, LOGOUT_USER, loadUser } from "./user";
import { START_PROGRESS, STOP_PROGRESS } from "./progress";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CHECK_AUTH_STATUS = "CHECK_AUTH_STATUS";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS }); // Inicia o progresso
    const { data } = await api.login(email, password);
    dispatch({ type: LOGIN_SUCCESS, payload: { token: data.token } });
    // Atualiza o estado do usuário
    dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
  } catch (error) {
    console.error("Login Error: ", error);
    dispatch({ type: LOGIN_FAIL, payload: error.message });
  } finally {
    dispatch({ type: STOP_PROGRESS }); // Para o progresso
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS }); // Inicia o progresso
    await api.logout();
    dispatch({ type: LOGOUT });
    dispatch({ type: LOGOUT_USER }); // Limpa o estado do usuário
  } catch (error) {
    console.error("Logout Error: ", error);
  } finally {
    dispatch({ type: STOP_PROGRESS }); // Para o progresso
  }
};

export const checkAuthStatus = () => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS });
    const { data } = await api.checkAuthStatus();
    dispatch({
      type: CHECK_AUTH_STATUS,
      payload: { authenticated: data.authenticated },
    });

    if (data.authenticated) {
      await dispatch(loadUser()); // Despacha a ação de carregar o usuário
    }
  } catch (error) {
    console.error("Check Auth Status Error: ", error);
    dispatch({
      type: CHECK_AUTH_STATUS,
      payload: { authenticated: false },
    });
  } finally {
    dispatch({ type: STOP_PROGRESS }); // Para o progresso
  }
};
