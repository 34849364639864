import * as api from "../api/user";
import { login } from "./auth";
import { START_PROGRESS, STOP_PROGRESS } from "./progress";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const UPDATE_LOJA_SUCCESS = "UPDATE_LOJA_SUCCESS";
export const UPDATE_LOJA_FAIL = "UPDATE_LOJA_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_FORNECEDOR_SUCCESS = "UPDATE_FORNECEDOR_SUCCESS";
export const UPDATE_FORNECEDOR_FAIL = "UPDATE_FORNECEDOR_FAIL";
export const CRIAR_FORNECEDOR_SUCCESS = "CRIAR_FORNECEDOR_SUCCESS";
export const CRIAR_FORNECEDOR_FAIL = "CRIAR_FORNECEDOR_FAIL";

// Exemplo de uma ação para iniciar o carregamento
export const startLoading = () => ({
  type: START_LOADING,
});

// Exemplo de uma ação para parar o carregamento
export const stopLoading = () => ({
  type: STOP_LOADING,
});

export const registerUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS }); // Inicia o progresso
    const { data } = await api.cadastrarUsuario(user);
    // Despacha a ação de registro
    dispatch({ type: REGISTER_SUCCESS, payload: data });
    // Despacha a ação de login automaticamente após o registro
    await dispatch(login(user.email, user.password));
    
  } catch (error) {
    console.error("Register User Error: ", error);
    dispatch({
      type: REGISTER_FAIL,
      payload: error.response?.data?.message || "Erro ao cadastrar o usuário.",
    });
  } finally {
    dispatch({ type: STOP_PROGRESS });
  }
};

export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS }); // Inicia o progresso
    dispatch({ type: START_LOADING });
    const { data } = await api.getUser();
    dispatch({ type: LOAD_USER_SUCCESS, payload: data });
  } catch (error) {
    console.error("Load User Error: ", error);
  } finally {
    dispatch({ type: STOP_PROGRESS });
    dispatch({ type: STOP_LOADING });
  }
};

export const atualizarLojaAction = (lojaForm) => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS });
    dispatch({ type: START_LOADING });

    // Constrói o FormData no frontend e envia para a API
    const formData = new FormData();
    Object.entries(lojaForm).forEach(([key, value]) => {
      if (key === "profile_picture" && value instanceof Blob) {
        formData.append(key, value); // Adiciona o arquivo
      } else {
        formData.append(key, value); // Adiciona outros campos
      }
    });

    // Chama a API para atualizar os dados da loja
    const { data } = await api.atualizarLoja(formData);

    dispatch({ type: UPDATE_LOJA_SUCCESS, payload: data });
    alert("Informações da loja atualizadas com sucesso!");
  } catch (error) {
    console.error("Erro ao atualizar loja: ", error);
    dispatch({
      type: UPDATE_LOJA_FAIL,
      payload: error.response?.data?.message || "Erro ao atualizar a loja.",
    });
    alert("Erro ao atualizar a loja. Tente novamente.");
  } finally {
    dispatch({ type: STOP_PROGRESS });
    dispatch({ type: STOP_LOADING });
  }
};

export const atualizarUsuarioAction = (userData) => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS });
    dispatch({ type: START_LOADING });

    const { data } = await api.atualizarUsuario(userData);

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
    alert("Informações pessoais atualizadas com sucesso!");
  } catch (error) {
    console.error("Erro ao atualizar usuário: ", error);
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response?.data?.message || "Erro ao atualizar o usuário.",
    });
    alert("Erro ao atualizar o usuário. Tente novamente.");
  } finally {
    dispatch({ type: STOP_PROGRESS });
    dispatch({ type: STOP_LOADING });
  }
};

export const atualizarFornecedorAction = (fornecedorData) => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS });
    dispatch({ type: START_LOADING });

    const formData = new FormData();
    Object.entries(fornecedorData).forEach(([key, value]) => {
      if (key === "profile_picture" && value instanceof Blob) {
        formData.append(key, value);
      } else if (value !== null && value !== undefined && value !== "") {
        formData.append(key, value);
      }
    });

    const { data } = await api.atualizarFornecedor(formData);

    dispatch({
      type: UPDATE_FORNECEDOR_SUCCESS,
      payload: data,
    });
    alert("Informações do fornecedor atualizadas com sucesso!");
  } catch (error) {
    dispatch({
      type: UPDATE_FORNECEDOR_FAIL,
      payload: error.response?.data?.message || "Erro ao atualizar fornecedor.",
    });
    console.log("ERRO  : ", error);
    alert("Erro ao atualizar o fornecedor. Tente novamente.");
  } finally {
    dispatch({ type: STOP_PROGRESS });
    dispatch({ type: STOP_LOADING });
  }
};

export const criarFornecedorAction = (fornecedorData) => async (dispatch) => {
  try {
    dispatch({ type: START_PROGRESS });
    dispatch({ type: START_LOADING });

    const formData = new FormData();
    Object.entries(fornecedorData).forEach(([key, value]) => {
      if (key === "profile_picture" && value instanceof Blob) {
        formData.append(key, value);
      } else if (value !== null && value !== undefined && value !== "") {
        formData.append(key, value);
      }
    });

    const { data } = await api.criarFornecedor(formData);

    dispatch({
      type: CRIAR_FORNECEDOR_SUCCESS,
      payload: data,
    });
    alert("Fornecedor cadastrado com sucesso!");
  } catch (error) {
    dispatch({
      type: CRIAR_FORNECEDOR_FAIL,
      payload: error.response?.data?.message || "Erro ao cadastrar fornecedor.",
    });
    alert("Erro ao cadastrar o fornecedor. Tente novamente.");
  } finally {
    dispatch({ type: STOP_PROGRESS });
    dispatch({ type: STOP_LOADING });
  }
};
