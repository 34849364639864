import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextInput, Button, Label, Checkbox } from "flowbite-react";
import { alterarSenha } from "../../api/user";
import NProgress from "nprogress";
import UserForm from "./UserForm";
import LojaForm from "./LojaForm";
import PasswordForm from "./PasswordForm";
import { atualizarLojaAction } from "../../actions/user"; // Action para atualizar loja

const EditUser = () => {
  const dispatch = useDispatch();

  // Pega o perfil do usuário do Redux
  const { profile } = useSelector((state) => state.user);
  const isLojista = profile?.role === "lojista";

  // Estado para o formulário de senha
  const [passwordForm, setPasswordForm] = useState({
    senhaAtual: "",
    novaSenha: "",
  });
  const [passwordError, setPasswordError] = useState("");

  // Estado para o formulário da loja
  const [lojaForm, setLojaForm] = useState(
    isLojista
      ? {
          nome_fantasia: profile?.loja?.nome_fantasia || "",
          cpf_cnpj: profile?.loja?.cpf_cnpj || "",
          endereco: profile?.loja?.endereco || "",
          telefone: profile?.loja?.telefone || "",
          whatsapp: profile?.loja?.whatsapp || "",
          opt_in: profile?.loja?.opt_in || false,
          profile_picture: profile?.loja?.profile_picture || null,
        }
      : null
  );

  const [cpfCnpjError, setCpfCnpjError] = useState("");

  // Atualiza os campos do formulário da loja
  const handleLojaChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLojaForm({
      ...lojaForm,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Atualiza a foto de perfil da loja
  const handleProfilePictureUpdate = (blob) => {
    setLojaForm((prevForm) => ({
      ...prevForm,
      profile_picture: blob,
    }));
  };

  // Envia os dados do formulário da loja
  const handleSubmitLoja = (e) => {
    e.preventDefault();

    // Valida CPF ou CNPJ
    const validateCpfCnpj = (cpfCnpj) => /^(?:\d{11}|\d{14})$/.test(cpfCnpj);

    if (!validateCpfCnpj(lojaForm.cpf_cnpj)) {
      setCpfCnpjError("CPF ou CNPJ inválido. Use apenas números.");
      return;
    }

    setCpfCnpjError("");

    // Dispara a action para atualizar a loja no backend
    dispatch(atualizarLojaAction(lojaForm));
  };

  // Valida a força da senha
  const validatePasswordStrength = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    return regex.test(password);
  };

  // Atualiza os campos do formulário de senha
  const handlePasswordChange = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };

  // Envia os dados do formulário de senha
  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    if (!passwordForm.senhaAtual || !passwordForm.novaSenha) {
      setPasswordError("Por favor, preencha todos os campos.");
      return;
    }

    if (!validatePasswordStrength(passwordForm.novaSenha)) {
      setPasswordError(
        "A nova senha deve ter pelo menos 6 caracteres, incluindo letras e números."
      );
      return;
    }

    if (passwordForm.novaSenha !== passwordForm.confirmarNovaSenha) {
      setPasswordError("As senhas não coincidem.");
      return;
    }

    setPasswordError("");

    try {
      NProgress.start();
      await alterarSenha(
        profile.id,
        passwordForm.senhaAtual,
        passwordForm.novaSenha
      );
      setPasswordForm({
        senhaAtual: "",
        novaSenha: "",
        confirmarNovaSenha: "",
      });
      alert("Senha alterada com sucesso!");
    } catch (error) {
      setPasswordError(
        error.response?.data?.mensagem || "Erro ao alterar a senha."
      );
    } finally {
      NProgress.done();
    }
  };

  return (
    <div className="relative isolate bg-white px-6 lg:px-8">
      <div className="mx-auto max-w-3xl py-16 sm:py-20 lg:py-24">
        <h1 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
          Editar Informações
        </h1>

        {/* Formulário de Informações Pessoais */}
        <UserForm profile={profile} />

        {isLojista && <hr className="my-6 border-t-2 border-gray-200" />}

        {/* Formulário de Informações da Loja */}
        {isLojista && (
          <LojaForm
            lojaForm={lojaForm}
            handleLojaChange={handleLojaChange}
            handleSubmitLoja={handleSubmitLoja}
            cpfCnpjError={cpfCnpjError}
            profilePicture={lojaForm.profile_picture}
            onProfilePictureUpdate={handleProfilePictureUpdate}
          />
        )}

        <hr className="my-6 border-t-2 border-gray-200" />

        {/* Formulário de Alteração de Senha */}
        <PasswordForm
          passwordForm={passwordForm}
          handlePasswordChange={handlePasswordChange}
          handleSubmitPassword={handleSubmitPassword}
          passwordError={passwordError}
        />
      </div>
    </div>
  );
};

export default EditUser;
