import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Cadastro de usuário
export function cadastrarUsuario(user) {
  return axios.post(`${API_URL}/user`, user);
}

// Busca informações do usuário
export function getUser() {
  return axios.get(`${API_URL}/user`);
}

// Altera a senha do usuário
export function alterarSenha(id, senhaAtual, novaSenha) {
  return axios.put(`${API_URL}/user/${id}/senha`, {
    senhaAtual,
    novaSenha,
  });
}

export function atualizarLoja(formData) {
  return axios.put(`${API_URL}/lojas`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function atualizarUsuario(userData) {
  return axios.put(`${API_URL}/user`, userData);
}

export function atualizarFornecedor(formData) {
  return axios.put(`${API_URL}/fornecedores/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function criarFornecedor(formData) {
  return axios.post(`${API_URL}/fornecedores`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
